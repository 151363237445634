<template>
  <div>
    <b-row>
      <b-col cols="3">
        <flat-picker
          id="month"
          v-model="month"
          style="margin-top: 5px;"
          placeholder="Select Date Range"
          :config="flatpickrConfig"
        />
      </b-col>
      <b-col cols="8">
        <validation-observer ref="busForm">
          <b-row
            :style="{
              border: isTableShow ? '1px solid blueviolet' : 'none',
              padding: '5px',
              borderRadius: '10px'
            }"
          > <feather-icon
              v-if="!isTableShow"
              v-b-tooltip.hover="{ boundary: 'window' }"
              title="Expand Table"
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              style="margin-top: 8px;"
              @click="isTableShow = true"
            />
            <feather-icon
              v-else
              v-b-tooltip.hover="{ boundary: 'window' }"
              title="Close Table"
              icon="ChevronDownIcon"
              size="20"
              class="cursor-pointer"
              style="margin-top: 7px;"
              @click="isTableShow = false"
            />

            <b-table-simple
              v-if="isTableShow"
              responsive
              class="compact-table"
            >
              <b-tbody>
                <b-tr
                  v-for="(party, partyIndex) in party_targets"
                  :key="partyIndex"
                >
                  <!-- Party Select Field with Validation -->
                  <b-td>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="`Party`"
                      :vid="`party_${partyIndex}_buyer`"
                      rules="required"
                    >
                      <div class="d-flex flex-column">
                        <b-form-select
                          v-model="party.buyer"
                          :options="partyOptions"
                          :state="errors.length === 0 ? null : false"
                          label="name"
                          class="w-100"
                        >
                          <!-- Placeholder option -->
                          <option
                            disabled
                            value=""
                          >
                            Select Party
                          </option>
                        </b-form-select>
                        <!-- Reserved space for error message -->
                        <small
                          v-if="errors.length"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                        <small
                          v-else
                          style="visibility: hidden;"
                        >Placeholder</small>
                      </div>
                    </validation-provider>
                  </b-td>

                  <!-- Target Amount Input Field with Validation -->
                  <b-td>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Target Amount"
                      :vid="`party_${partyIndex}_target_amount`"
                      rules="required|min_value:1"
                    >
                      <div class="d-flex flex-column">
                        <b-form-input
                          v-model.number="party.target_amount"
                          :state="errors.length === 0 ? null : false"
                          type="number"
                          placeholder="Enter Target Amount"
                          class="w-100"
                        />
                        <!-- Reserved space for error message -->
                        <small
                          v-if="errors.length"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                        <small
                          v-else
                          style="visibility: hidden;"
                        >Placeholder</small>
                      </div>
                    </validation-provider>
                  </b-td>

                  <!-- Delete Party Button -->
                  <b-td>
                    <feather-icon
                      v-b-tooltip.hover="{ boundary: 'window' }"
                      title="Delete Party"
                      icon="TrashIcon"
                      size="20"
                      class="cursor-pointer text-danger"
                      style="margin-bottom: 15px;"
                      @click="deleteParty(partyIndex)"
                    />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <!-- Add Party and Save Buttons -->
            <div
              v-if="isTableShow"
              class="d-flex justify-content-between w-100 "
            >
              <b-button
                variant="outline-primary"
                @click="addParty"
              >
                Add Party
              </b-button>
              <div class="d-flex align-items-center justify-content-end px-1 w-50 gap-2">
                <span style="margin-right: 10px; font-size: medium;">Total: {{ calculateTotalTarget }}</span>
                <b-button
                  variant="primary"
                  :disabled="loading"
                  @click="handleSave"
                >
                  <span>Save</span>
                  <b-spinner
                    v-if="loading"
                    small
                    class="ml-2"
                  />
                </b-button>
              </div>
            </div>
          </b-row>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dateMixin from '@/mixins/dateMixin'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import 'flatpickr/dist/flatpickr.css'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import {
  BButton,
  VBTooltip,
  BSpinner,
  BFormSelect,
  BTableSimple,
  BFormInput,
  BRow,
  BCol,
  BTr,
  BTbody,
  BTd,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { min_value as minValueRule } from 'vee-validate/dist/rules'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
// Extend the validation rule
extend('min_value', {
  ...minValueRule,
  message: 'The value must be at least {min}.',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    FlatPicker,
    BRow,
    BSpinner,
    BCol,
    BTableSimple,
    BTr,
    BTbody,
    BTd,
    BFormSelect,
    BButton,
    BFormInput,
  },
  mixins: [dateMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    partyOptions: {
      type: Array,
      required: true,
    },
    oldData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      month: `${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
      flatpickrConfig: {
        dateFormat: 'm/Y',
        altInput: true,
        altFormat: 'F Y',
        plugins: [new MonthSelectPlugin({ shorthand: true, dateFormat: 'm/Y', altFormat: 'F Y' })],
      },
      party_targets: this.oldData.length
        ? this.oldData.map(item => ({
          buyer: item.buyer || '', // Use item's id or default to an empty string
          target_amount: item.target_amount || null,
          id: item.id || null, // Use item's target_amount or default to null
        }))
        : [{ buyer: '', target_amount: null }],
      isTableShow: false,
      deleted_list: [],
      loading: false,
    }
  },
  computed: {
    calculateTotalTarget() {
      const total = this.party_targets
        .map(item => item.target_amount)
        .filter(targetItem => targetItem !== null)
        .reduce((a, b) => a + b, 0)
      return total > 0 ? total : 0
    },
  },
  watch: {
    month() {
      this.fetchMonthlyItems()
    },
  },
  methods: {
    handleSave() {
      this.$refs.busForm.validate().then(isValid => {
        if (!isValid) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please correct the form errors',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } else if (this.party_targets.length < 1 && this.deleted_list.length === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please Add At least one party',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } else {
          this.saveTarget()
        }
      })
    },
    async saveTarget() {
      this.loading = true
      let data = this.party_targets.map(partyTargets => ({
        salesman: this.id,
        buyer: partyTargets.buyer,
        year: +this.month.split('/')[1],
        month: +this.month.split('/')[0],
        target_amount: partyTargets.target_amount,
        id: partyTargets.id,
      }))
      if (this.deleted_list.length > 0) {
        data = [...data, ...this.deleted_list]
      }
      const operation = axios.post('/accounts/salesman-targets/salesman_traget_bulk_operations', { targets: data })
      const message = data.filter(item => item.id).length > 0 ? 'Targets updated successfully' : 'Targets created successfully'

      operation
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.loading = false
          this.$refs.busForm.reset()
          this.$emit('fetchItemsAfterSave')
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.error || 'Error in updating or creating targets',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.$refs.busForm.reset()
          this.loading = false
        })
    },
    async fetchMonthlyItems() {
      try {
        const res = await axios.get(`/accounts/get-salesman-targets/?month=${+this.month.split('/')[0]}&year=${+this.month.split('/')[1]}&salesman_id=${this.id}`)
        this.party_targets = res?.data?.length
          ? res?.data?.map(item => ({
            buyer: item.buyer || '', // Use item's id or default to an empty string
            target_amount: item.target_amount || null,
            id: item.id || null, // Use item's target_amount or default to null
          }))
          : [{ buyer: '', target_amount: null }]
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Something went wrong'
      }
    },
    addParty() {
      this.party_targets.push({
        buyer: '',
        target_amount: null,
      })
    },
    deleteParty(index) {
      if (this.party_targets[index].id) {
        this.deleted_list.push({
          operation: 'delete',
          id: this.party_targets[index].id,
        })
      }
      this.party_targets.splice(index, 1)
    },
  },
}
</script>
