<template>
  <b-card no-body>
    <b-card-header class="align-items-start">
      <h4>Sales Man</h4>
      <div class="flex-grow-1 ml-1">
        <b-spinner
          v-if="loading"
          small
          label="Small Spinner"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <b-alert
        variant="danger"
        :show="loadingError ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ loadingError }}
          </p>
        </div>
      </b-alert>
      <template v-if="!loadingError">
        <b-row
          v-if="filterMode === null"
          class="align-items-center"
        />
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Salesman"
              label-for="salesman"
            >
              <custom-v-select
                id="salesman"
                v-model="filtersData.salesmans"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="salesmanOptions"
                label="text"
                placeholder="Select Salesman"
                multiple
                :close-on-select="false"
                :deselect-from-dropdown="true"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <div class="dropdo">
              <button
                class="btn btn-primary dropdown-togg"
                @click="toggleDropdown"
              >
                Forward Targets <feather-icon
                  v-b-tooltip.hover="{ boundary: 'window' }"
                  title="Open Month"
                  icon="ChevronDownIcon"
                  size="20"
                  class="cursor-pointer"
                  style="margin-bottom: -4px;"
                />
              </button>
              <div
                v-if="isDropdownOpen"
                class="dropdown-men sho"
              >
                <b-form-group
                  label="From Month"
                  label-for="fromMonth"
                >
                  <flat-picker
                    id="fromMonth"
                    v-model="fromMonth"
                    placeholder="Select Date Range"
                    :config="flatpickrConfig"
                    @mousedown.stop
                  />
                </b-form-group>
                <b-form-group
                  label="To Month"
                  label-for="toMonth"
                >
                  <flat-picker
                    id="toMonth"
                    v-model="toMonth"
                    placeholder="Select Date Range"
                    :config="flatpickrConfig"
                    @mousedown.stop
                  />
                </b-form-group>
                <div style="display: flex; width: 100%;">
                  <b-button
                    variant="primary"
                    :disabled="forwarding"
                    style="margin-left: auto;"
                    @click="forwardTarget"
                  >
                    Forward
                    <b-spinner
                      v-if="forwarding"
                      small
                      label="Small Spinner"
                    />
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  VBTooltip, BButton, BRow, BCol, BFormGroup, BCard, BSpinner, BCardHeader, BCardBody, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isEqual } from 'lodash'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import 'flatpickr/dist/flatpickr.css'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BCard,
    CustomVSelect,
    BSpinner,
    FlatPicker,
    BCardHeader,
    BCardBody,
    BAlert,
    BButton,
  },
  data() {
    return {
      salesmanOptions: [],
      filters: [],
      filter: null,
      filtersData: {
        salesmans: [],
      },
      filterName: null,
      filterMode: null,
      forwarding: false,
      isDropdownVisible: true,
      isDropdownOpen: false,
      flatpickrConfig: {
        dateFormat: 'm/Y', // Format for binding (MM/YYYY)
        altInput: true, // Show formatted date in input
        altFormat: 'F Y', // Readable format (e.g., September 2024)
        plugins: [new MonthSelectPlugin({ shorthand: true, dateFormat: 'm/Y', altFormat: 'F Y' })],
      },
      fromMonth: `${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
      toMonth: `${new Date().getMonth() + 2}/${new Date().getFullYear()}`,
      loading: true,
      loadingError: null,
      oldOutputFiltersData: {},
      initialized: false,
    }
  },
  computed: {
    outputFiltersData() {
      const filtersData = {}
      if (this.filtersData.salesmans.length > 0) {
        filtersData.salesmans = this.filtersData.salesmans
      }
      return filtersData
    },
  },
  watch: {
    outputFiltersData: {
      deep: true,
      handler() {
        if (!this.initialized) {
          return
        }
        if (isEqual(this.oldOutputFiltersData, this.outputFiltersData)) {
          return
        }
        this.emitOutputFiltersData()
      },
    },
  },
  async created() {
    this.initializeFilters()
  },
  methods: {
    async initializeFilters() {
      await this.fetchOptions()
      if (this.loadingError) {
        return
      }
      this.emitOutputFiltersData()
      this.initialized = true
    },
    async fetchOptions() {
      this.loading = true
      let res
      try {
        res = await axios.get('/accounts/salesmans_options')
        this.salesmanOptions = res.data.data
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Error fetching filter options'
      }
      this.loading = false
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    async forwardTarget() {
      this.forwarding = true
      function adjustMonthYear(monthYear) {
        let [month, year] = monthYear.split('/').map(Number)
        year += Math.floor((month - 1) / 12)
        month = ((month - 1) % 12) + 1
        return { month, year }
      }

      const adjustedFrom = adjustMonthYear(this.fromMonth)
      const adjustedTo = adjustMonthYear(this.toMonth)

      const req = axios.post('/accounts/forward-targets/', {
        from_month: adjustedFrom.month,
        from_year: adjustedFrom.year,
        to_month: adjustedTo.month,
        to_year: adjustedTo.year,
      })
      const errorMessage = 'Error creating filter'
      req.then(j => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: j.data.message || 'Target created successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.forwarding = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.error || errorMessage,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.forwarding = false
      })
    },
    setFiltersData() {
      if (!this.filter) {
        this.filtersData = {
          salesmans: [],
        }
      } else {
        this.filtersData = {
          salesmans: this.filter.filters.salesmans || [],
        }
      }
    },
    emitOutputFiltersData() {
      this.oldOutputFiltersData = this.outputFiltersData
      this.$emit('filtersUpdated', this.outputFiltersData)
    },
  },
}
</script>

<style>
/* Main Dropdown Container */
.dropdo {
  position: relative;
  display: inline-block;
  margin-top: 20px;
}

/* Dropdown Button */
.dropdown-togg {
  cursor: pointer;
  outline: none;
}

/* Dropdown Menu */
.dropdown-men {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 225px;
  padding: 0.8rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

/* Dropdown Menu - Show State */
.sho {
  display: block;
}
</style>
