<template>
  <div>
    <!-- Loading Spinner -->
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <!-- Error Alert -->
    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>{{ loadingError }}</p>
      </div>
    </b-alert>

    <!-- Table -->
    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table table-cell-top"
      no-local-sorting
      @sort-changed="sortingChanged"
    >
      <template #cell(target)="data">
        {{ calculateTotal(data.item.oldData) }}
      </template>
      <template #cell(monthly_target)="data">
        <PartyWiseTarget
          :id="data.item.id"
          :party-options="partyOptions"
          :old-data="data.item.oldData"
          @fetchItemsAfterSave="fetchItemsAfterSave"
        />
      </template>
    </b-table>

    <!-- Pagination -->
    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => { currentPage = page }"
    />
  </div>
</template>

<script>
import {
  BSpinner,
  BTable,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import dateMixin from '@/mixins/dateMixin'
import PartyWiseTarget from './PartyWiseTarget.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BAlert,
    DetailedPagination,
    PartyWiseTarget,
  },
  mixins: [dateMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      showAddForm: false,
      currentPage: 1,
      perPage: 50,
      sortBy: 'received_date',
      results: [],
      totalItems: 0,
      sortDesc: true,
      tableFields: [
        {
          key: 'id', label: 'ID', sortable: true, thStyle: { width: '100px' }, tdClass: 'highlighted-cell',
        },
        {
          key: 'text', label: 'Name', sortable: true, thStyle: { width: '200px' }, tdClass: 'highlighted-cell',
        },
        {
          key: 'target', label: 'Total', sortable: true, thStyle: { width: '200px' }, tdClass: 'highlighted-cell',
        },
        { key: 'monthly_target', label: 'Month', sortable: true },
      ],
      openItemId: null,
      partyOptions: [],
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else if (this.filters.salesmans !== undefined) {
        this.fetchItems([...this.filters.salesmans])
      } else {
        this.fetchItems([])
      }
    },
  },
  created() {
    this.fetchPartyOptions()
    this.fetchItems([])
  },
  methods: {
    // Fetch items based on current filters or salesmans
    async fetchPartyOptions() {
      try {
        const partyResponse = await axios.get('/accounts/buyers_options')
        this.partyOptions = partyResponse?.data?.data.map(k => ({ value: k.id, text: k.text }))
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Something went wrong'
      }
    },
    async fetchItems(ary) {
      this.loading = true
      this.loadingError = null // Reset loading error
      try {
        const response = await axios.get('/accounts/salesmans_options')
        const monthlyData = await axios.get(`/accounts/get-salesman-targets/?month=${+new Date().getMonth() + 1}&year=${+new Date().getFullYear()}&all=all`)
        const updatedData = response.data.data.map(item => {
          const oldData = monthlyData.data.filter(oldItem => item.id === oldItem.salesman) || []
          return { ...item, oldData }
        })
        const data = updatedData || []
        if (!ary || ary.length === 0) {
          this.results = data.sort((a, b) => {
            if (a.id === 1) return -1 // If `a` has id: 1, place it first
            if (b.id === 1) return 1 // If `b` has id: 1, place it first
            return a.id - b.id // Otherwise, sort in ascending order
          })
        } else {
          this.results = data
            .filter(salesman => ary.includes(salesman.id))
            .sort((a, b) => {
              if (a.id === 1) return -1 // If `a` has id: 1, place it first
              if (b.id === 1) return 1 // If `b` has id: 1, place it first
              return a.id - b.id // Otherwise, sort in ascending order
            })
        }
        this.totalItems = this.results.length
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Something went wrong'
      } finally {
        this.loading = false
      }
    },
    fetchItemsAfterSave() {
      if (this.filters.salesmans !== undefined) {
        this.fetchItems([...this.filters.salesmans])
      } else {
        this.fetchItems([])
      }
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    calculateTotal(oldItems) {
      const total = oldItems
        .map(item => item.target_amount)
        .filter(targetItem => targetItem !== null)
        .reduce((a, b) => a + b, 0)
      return total > 0 ? total : 0
    },
    displayDetails(itemId) {
      this.openItemId = itemId
    },
  },
}
</script>
<style>
.table-cell-top td.highlighted-cell {
  vertical-align: top !important;
  padding-top: 20px !important;
  box-sizing: border-box;
}
</style>
